body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card--status{
  position: absolute;
  right: 32px;
}

.tg-adcontent > p {
  color: black;
}


.imgFigure{
  z-index: 2;
  position: relative;
  -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.20);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.20);
}

.imgStyle{
  -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.20);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.20);
  padding: 5px;
}

.pricingTable{
}

.pricingTable tr{
  border: 1px solid gray;
  border-radius: 5px;
}

.pricingTable td{
  padding: 5px 10px;
  border-right: 1px solid gray !important;
  text-align: center !important;
  width: 33%;
}
.pricingTable tr:hover{
  background-color: #f3f3f3;
}

.accord{
  border: 1px solid #EDEDED;
  border-radius: 5px;
  margin: 5px;
  background: #363a4d;
}

.accord-text{
  font-size: 18px;
  color: white;
  text-decoration: none;
}

.tableWidth{
  max-width:600px;
}

.doc{
  margin-top: 20px;
  background: #363b4d;
  padding: 15px;
  border-radius: 10px;
}

.text-white{
  color: white !important;
}
.dmimg{
    width:100% !important;
    height:200px !important;
}


.quickcontact{
  width: 300px;
  border: 1px solid #b9b9b9;
  padding: 15px;
  border-radius: 10px;
  top: 190px;
  right: 0;
  background: white;
  position: fixed;
}


.w-100{
  width: 100%!important;
}

.is-modal-icon{
  position: fixed;
  top: 280px;
  font-size: 60px;
  right: 0px;
  width: 50px;
  height: 60px;
}

.card-body{
  padding: 30px!important;
  background: #f1f1f1;
  border-radius: 5px;
  margin: 10px;
}

.hr-custom{
  box-sizing: content-box;
  height: 0;
  margin-top: -5px;
  color: black;
  border: 1px solid #c1c1c1;
}


.upper{
  text-transform: uppercase;
}


.tg-themetag {
  top: 85% !important;
}

.box {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  background-color: #f9f9f9;
  padding: 80px;
}

.hidebox{
  display: none !important;
}


.showbox{
  display: block !important;
}



input.form-control {
  text-transform: none !important;
}


.nav-mobile{
  height: 46px !important;
  width: 60px;
  background: #5ecd69;
  border: 1px solid #5ecd69;
  box-shadow: none;
}


@media only screen and (min-width: 484px) {
  .hide-me{
    display: none;
  }
}


@media only screen and (max-width: 600px) {
  .box{
    padding: 20px !important;
  }
}

.ab{
  padding: 5px 5px 5px 15px;
}

.ab >li {
  text-align: left !important;
}


.ft-patch{
    background: rgba(0,0,0,0.20);
    padding: 15px 0px;
    color: white;
}


.ft-patch .container ul li {
    float: left;
    padding-right: 15px;
    font-size: 14px
}

.ft-patch .container ul li a {
    color: white;
}

.ft-patch .container ul li a:hover{
    color: green;
}


.wrap{
  wordWrap: 'break-word'
}


.nmn{
  background: #363b4d !important;
}


.login-text{
  color: white;
}

.login-text:hover{
  color: white;
}

.btn-register{
  background: #5ecd69;
  color: white;
}

.btn-register:hover{
  background: #4dbb58;
  color: white;
}



.dropbtn {
  color: white;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.user-ml{
  margin-left: 250px;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}


.profileTable td{
  padding-bottom: 15px;
}
.display{
  display: block!important;
}
.dontDisplay{
  display: none!important;
}


@media only screen and (max-width: 480px) {
    .content {
        width: 100% !important;
    }

    .sm-sb {
        width: 60px !important;
    }

    .hide-sb {
        display: none !important;
    }

    .tg-main {
        padding: 0px !important;
    }


    .user-ml {
        margin-left: 60px !important;
    }

    .tg-dashboardbanner {
        padding: 15px 15px;
    }

    .show-sb{
      display: block !important;
    }

    .tg-dashboardbanner > h1{
      display: none;
    }

    .tg-dashboardheader{
      display: none;
    }

}
textarea{
    text-transform: none !important;
}

.similar{
  background: #f1f1f1;
    border-radius: 5px;
    padding: 15px 10px 15px 10px;
}

i.fa.fa-heart.selectedFav {
  background: red;
    color: #fff;
    height: 33px;
    margin-top: -3px;
    border-radius: 3px;
}


.card-bg{
  border: 1px solid #f1f1f1;
}

.tg-dashboardtable tbody tr td, .tg-dashboardtable tbody tr td, .tg-dashboardtable tbody tr td time{
  border: 1px solid #dbdbdb !important;
}
