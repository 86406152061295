.video-st{
    position: absolute; z-index: -1; top: 0px; left: 0px; bottom: 0px; right: 0px; overflow: hidden; background-size: cover; background-color: transparent; background-repeat: no-repeat; background-position: 50% 50%; 
}


.video-hm{
    margin: auto; position: absolute; z-index: -1; top: 50%; left: 50%; transform: translate(-50%, -50%); visibility: hidden; opacity: 0;
}


.showBar{
    position: absolute;
    margin-top: 70px;
    background: white;
    width: 27%;
    border-radius: 5px;
}


.showBarlist{
    color: black;
    text-align: left;
}

.showBarlist li{
    padding: 8px 20px;
    list-style: none ;
    border-bottom: 1px solid gainsboro;
    font-size: 15px;
    color: #666;
}

.showBarlist li:hover{
    background: #f3f3f3;
}

.showBarlist li:first-child:hover{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}



.showBarlist li:last-child{
    border-bottom: 1px solid gainsboro;
    border-radius: 5px;
}
