.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active:hover {
    background: transparent;
    border: none;
    box-shadow: none;
}

li {
list-style: none;
}


.card-b {
    padding: 0px !important;
    background: none;
    border-radius: 5px;
    margin: 10px;
}
