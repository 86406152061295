.tg-adtitle:before {
    display: none!important;
}


.ip-status{
    border: 1px solid gray;
    padding: 2px 15px;
    border-radius: 15px;
    font-size: 15px;
}

td{
    border: none !important;
    text-align: left !important;
}

tr{
    text-align: left !important;
}

.tg-ad figure {
    height: 190px;
}

.cstmh {
    min-height: 410px;
}

.cborder{
    border: 1px solid 1px solid #f1f1f1;
}

.pb-50{
    padding-bottom: 50px !important;
}

.pt-50{
    padding-top: 50px !important;
}


