
.modal.fade.show {
    display: block;
    padding-right: 16px;
    opacity: 1;
}

.modal-content {
    margin-top: 12%;
}


.m-icon{
    position: absolute;
    right: 15px;
    margin-top: -40px;
    cursor: pointer;
    font-size: 22px;
}
